import React from 'react';
import styled, { css } from 'styled-components';

// Component Imports
import { PageSubtitle, Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import SEO from '../components/seo';
import Container from '../components/container';
import Button from '../components/buttons';
import AnimatedStripe from '../components/animatedStripe';

// Asset Imports
import Stripes11SVG from '../images/stripes/stripes11.inline.svg';
import { P1 } from '../theme/typography';

// Styled Components
const PageStripes = styled(AnimatedStripe)`
  position: absolute;
  top: 25%;
  left: -40%;
  width: 100%;
  height: 70%;
  pointer-events: none;
  z-index: 0;

  @media (min-width: 64em) {
    top: 15%;
    left: -7%;
    width: 40%;
  }
`;

const Tile = styled.div`
  background: var(--blue6);
  border-radius: 15px;
  box-shadow: var(--box-shadow-lg);
  margin: 0 auto;
  padding: 32px 48px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  position: relative;
  z-index: 1;

  @media (min-width: 45em) {
    flex-direction: row;
  }
`;

const UrgentQuery = styled(P1)`
  text-align: center;
  line-height: 1.5;
  margin-bottom: 24px;

  strong {
    font-weight: var(--font-weight-semibold);
  }

  @media (min-width: 45em) {
    text-align: left;
    margin-bottom: 0;
  }
`;

const CallButton = styled.a`
  width: 100%;

  @media (min-width: 37.5em) {
    width: unset;
  }
`;

const ThankYouPage = () => (
  <>
    <SEO title='Thank You' />
    <Section dark>
      <Container>
        <PageStripes direction='left'>
          <Stripes11SVG />
        </PageStripes>
        <CenteredHeaderBlock
          data={{
            preHeader: 'Thank You',
            title: 'Message Submitted Successfully',
            body: 'Your message has been sent sucessfully and one of the Rubberduck team will get back to you shortly!',
          }}
          style={{ marginBottom: '32px' }}
        />
        <Tile>
          <UrgentQuery>
            Is your query urgent? <br /> Call us on <strong>086 7377087</strong>
          </UrgentQuery>
          <CallButton href='tel:0867377087'>
            <Button type='secondary' label='Call Rubberduck' arrow />
          </CallButton>
        </Tile>
      </Container>
    </Section>
  </>
);

export default ThankYouPage;
